export const calcTotalPrice = (items) => {
  if (!items || items.length === 0) {
    return 0;
  }
  return items.reduce(
    (acc, product) => (acc += product.price * product.count),
    0
  );
};

export const enumerate = (num, dec) => {
  if (num > 100) num = num % 100;
  if (num <= 20 && num >= 10) return dec[2];
  if (num > 20) num = num % 10;
  return num === 1 ? dec[0] : num > 1 && num < 5 ? dec[1] : dec[2];
};
