export const CATEGORY_RENT=[
    {
        title:'ВСЁ',
        // type:'all',
      
    },
    {
        title:'ПАЛАТКИ',
        type:'rentTent',
       
    },
    {
        title:'СПАЛЬНЫЕ МЕШКИ',
        type:'rentSleeping',
     
    },
    {
        title:'РЮКЗАКИ',
        type:'rentBack',
     
     
    },
    {
        title:'КЕМПИНГОВАЯ МЕБЕЛЬ',
        type:'rentFURNITURE',
      
    },
    {
        title:'ПОСУДА',
        type:'rentDISHES',
      
    },
    {
        title:'ГОРЕЛКИ',
        type:'rentBURNER',
        id:7
    },
    {
        title:'ИНСТРУМЕНТЫ',
        type:'rentTOOLS',
        id:8
    },
    {
        title:'ПРЕДМЕТЫ ПОХОДА',
        type:'rentHIKING',
        id:9
    },
    {
        title:'ДЛЯ АЛЬПИНИЗМА',
        type:'rentCLIMBING',
        id:10
    },
    {
        title:'САП-БОРДЫ И АКСЕССУАРЫ',
        type:'rentSUP',
        id:11
    },
    {
        title:'ТЕХНИКА',
        type:'rentTECHNICS',
        id:12
    },
    

]