export const CATEGORY_PRODUCT=[
    {
        title:'ВСЁ',
        // type:'all',
      
    },
    {
        title:'ПАЛАТКИ',
        type:"tent",
       
    },
    {
        title:'АКСЕССУАРЫ',
        type:"accessories",
       
    },
    {
        title:'СПАЛЬНЫЕ МЕШКИ',
        type:"sleepingbag",
       
    },
    {
        title:'РЮКЗАКИ',
        type:"backpack",
       
    },
    {
        title:'КОВРИКИ / КАРЕМАТЫ',
        type: "mat",
       
    },
    {
        title:'ТРЕКИНГОВЫЕ ПАЛКИ',
        type: "trekkingsticks",
       
    },
    {
        title:'ДОЖДЕВИКИ / ГЕРМОМЕШКИ',
        type: "hermo",
       
    },
    {
        title:'ГАЗ / ГАРЕЛКИ',
        type:"gas/burner",
       
    },
    {
        title:'ФОНАРИ',
        type:"lantern",
       
    },
    {
        title:'СОЛНЦЕЗАЩИТНЫЕ ОЧКИ',
        type:"sunglasses",
       
    },
    {
        title:'Термос / Терморюкзак',
        type:"termoryukzak",
       
    },
    {
        title:'Бутылки',
        type:"bottle",
       
    },
    {
        title:'ПОСУДА',
        type:"dishes",
       
    },
    {
        title:'НОЖИ',
        type:"knife",
       
    },
    {
        title:'КЕМПИНГОВАЯ МЕБЕЛЬ',
        type:"chair",
       
    },
    {
        title:'БАФФЫ / БАЛАКЛАВЫ',
        type:"buff",
       
    },
 
    {
        title:'НАДУВНАЯ ПРОДУКЦИЯ',
        type:"air",
       
    },
    {
        title:'Походная еда',
        type:"sublimates",
       
    },
 
    {
        title:'SUP-доски',
        type:"supboard",
       
    },
    {
        title:'Обувь',
        type:"shoes",
       
    },
    {
        title:'SUP-доски',
        type:"supboard",
       
    },
 
 
    

]